import * as http from "shared/http";
import { Paginated, TrajectoryActiveType } from "shared/types";
import useSWR from "swr";
import { useQuery } from "@tanstack/react-query";

export function getGoalsList() {
    return http.request<Paginated<TrajectoryActiveType>>({
        method: "get",
        url: `/api/v1/user/trajectory_actives`,
    });
}

export default function useGoalsList() {
    const { data, status, error } = useQuery({
        queryKey: ["actives-list"],
        queryFn: getGoalsList,
    });

    return {
        goalsList: data,
        isLoadingGoal: status === "pending",
        isErrorGoal: status === "error",
    };
}
