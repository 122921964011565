import { truncateText } from "utils/truncateText";
import { TrajectoryActiveType } from "shared/types";
import { ReactComponent as RoundTimline } from "../../shared/ui/icon/RoundTimline.svg";
import { ReactComponent as RoundTimlineNS } from "../../shared/ui/icon/RoundTimlineNS.svg";

interface Props {
    active: TrajectoryActiveType | undefined;
    setEditActive: React.Dispatch<React.SetStateAction<TrajectoryActiveType | undefined>>;
    setOpenThreats: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenMaterials: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoadingThreats: React.Dispatch<React.SetStateAction<boolean>>; 
    activeSelected: TrajectoryActiveType | undefined;
    activeListLenght: number;
    index: number;
}

export const ActiveCard: React.FC<Props> = ({
    active,
    setEditActive,
    setOpenThreats,
    setOpenMaterials,
    setIsLoadingThreats,
    activeSelected,
    index,
    activeListLenght
}) => {

    const activeStat = ((active?.completed_threats_count / active?.threats_count) || 0).toPrecision(1);
    const activePos = index + 1;

    function openActive(){
        setEditActive(active);
        setOpenThreats(true);
        setIsLoadingThreats(true);
    }

    function closeActive(){
        setEditActive(active);
        setOpenThreats(false);
        setOpenMaterials(false)
    }

    return (
            <div 
                className="relative flex min-h-[90px] w-[455px] gap-x-[30px] cursor-pointer"
                onClick={openActive}
            >

                <div className={`relative ${activeListLenght === activePos ? " " : "last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-[#F3A773]"} `}>
                    <div className="relative z-10 size-7 flex justify-center items-center">
                        {activeSelected.id === active.id ? (
                            <RoundTimlineNS />
                            
                        ):(
                            <RoundTimline />
                        )}
                    </div>
                </div>

                <div className="grow pt-0.5 pb-[60px] ">
                    <div className="flex w-full justify-between">
                        <div className="flex gap-x-[20px] ">
                            <p className="text-orange text-[24px] font-bold leading-[24px] w-[70px] text-start">
                                {Number(activeStat)*100} %
                            </p>
                            <div className="flex flex-col gap-y-[15px]">
                                <div className="flex min-w-[80px] max-w-[80px] h-[20px] bg-[#369F481A] rounded justify-center items-center">
                                    <p className="text-[#369F48] text-[12px] font-normal leading-[14.4px]">
                                        {active.threats_count} навыков
                                    </p>
                                </div>
                                <p className="flex w-[220px] text-[16px] font-medium leading-[19.2px] break-words">
                                    {truncateText(active?.active?.name, 2)}
                                </p>
                            </div>
                        </div>
                        
                        <div className="flex flex-col gap-y-[15px] items-end justify-end">
                            <div className="flex gap-x-[10px] text-[12px] leading-[14.4px] font-medium text-[#ADADAD]">
                                Открыть &#8594;
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>        
    );
};
