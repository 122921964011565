import * as http from "shared/http";
import { Paginated, ActiveThreatType } from "shared/types";
import useSWR from "swr";
import { useQuery } from "@tanstack/react-query";

export function getSkillsGoal(id: string) {
    return http.request<Paginated<ActiveThreatType>>({
        method: "get",
        url: `/api/v1/user/trajectory_active/${id}/threats`,
    });
}

export default function useSkillsGoal(id) {
    const { data, status, error } = useQuery({
        queryKey: ["skills-list", id],
        queryFn: () => getSkillsGoal(id),
    });

    return {
        skillList: data,
        isLoadingSkill: status === "pending",
        isErrorSkill: status === "error",
    };
}