import { useQuery } from "@tanstack/react-query";
import ContentAdapter from "pages/session/ContentAdapter";
import * as http from "shared/http";
import {
    TrajectoryActiveType,
    ActiveThreatType,
    Content,
    Paginated,
} from "shared/types";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as ListClose } from "../../shared/ui/icon/ListClose.svg";
import { ReactComponent as ListOpen } from "../../shared/ui/icon/ListOpen.svg";
import { ReactComponent as SelectedMaterial } from "../../shared/ui/icon/SelectedMaterial.svg";
import { ReactComponent as FinishedMaterial } from "../../shared/ui/icon/FinishedMaterial.svg";
import { useSessionPopup } from "./model";
import { useEffect, useState } from "react";
import { PopupTest } from "components/PopupTest/PopupTest";
import { truncateText } from "../../utils/truncateText";
import { Loader } from "components/Loader/Loader";

function getThreatContent(threatId: number) {
    return http.request<Paginated<Content>>({
        method: "get",
        url: `/api/v1/user/active_threat/${threatId}/content`,
    });
}

function getThreatDetails(threatId: number) {
    return http.request<ActiveThreatType>({
        method: "get",
        url: `/api/v1/active_threat/${threatId}`,
    });
}

function getActiveContent(activeId: number) {
    return http.request<Paginated<Content>>({
        method: "get",
        url: `/api/v1/user/trajectory_active/${activeId}/content`,
    });
}

function getActiveDetails(activeId: number) {
    return http.request<TrajectoryActiveType>({
        method: "get",
        url: `/api/v1/trajectory_active/${activeId}`,
    });
}

export function MaterialSessionPopup() {
    const activeId = useSessionPopup((state) => state.activeId);
    const threatId = useSessionPopup((state) => state.threatId);
    const materialId = useSessionPopup((state) => state.materialId);
    const setMaterialId = useSessionPopup((state) => state.setMaterialId);
    const closePopup = useSessionPopup((state) => state.close);
    const [openPopupTest, setOpenPopupTest] = useState(false);

    const { data: active, status: activeStatus } = useQuery({
        queryKey: ["MaterialSessionPopup--active--details", activeId],
        queryFn: () => getActiveDetails(activeId),
        enabled: activeId !== null,
    });

    const { data: activeData, status: activeDataStatus } = useQuery({
        queryKey: ["MaterialSessionPopup--active--materials", activeId],
        queryFn: () => getActiveContent(activeId),
        enabled: activeId !== null,
    });

    const { data: threat, status: threatStatus } = useQuery({
        queryKey: ["MaterialSessionPopup--threat--details", threatId],
        queryFn: () => getThreatDetails(threatId),
        enabled: threatId !== null,
    });

    const { data: threatData, status: threatDataStatus } = useQuery({
        queryKey: ["MaterialSessionPopup--threat--materials", threatId],
        queryFn: () => getThreatContent(threatId),
        enabled: threatId !== null,
    });

    const [selectedMaterial, setSelectedMaterial] = useState<Content>();

    const isError = activeStatus === "error" || activeDataStatus === "error";
    const isError2 = threatStatus === "error" || threatDataStatus === "error";
    const isPending =
        activeStatus === "pending" || activeDataStatus === "pending";
    const isPending2 =
        threatStatus === "pending" || threatDataStatus === "pending";

    const materials = activeData?.results || threatData?.results || [];
    const material = materials.find((m) => m.id === materialId);
    const [openList, setOpenList] = useState(false);
    const nextElements = materials.filter(
        (elem) => elem.id !== materialId && elem.session.percent_progress < 100
    )

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(isPending === false && threat === undefined){
            setIsLoading(false)
        }
        if(isPending2 === false && active === undefined){
            setIsLoading(false)
        }
        if(materialId !== null){
            setSelectedMaterial(materials.find((m) => m.id === materialId));
        } else {
            setSelectedMaterial(materials[0]);
        }
    }, [isPending, isPending2, active, threat]);
    // console.log(nextElements);
    // console.log(materials);
    // TODO вынести в компонент

    let component = () => {
        if (isError2 || isError) {
            return <div>Произошла ошибка при загрузке материала</div>;
        }
        if (isLoading === true) {
            return <Loader text="Загрузка"/>;
        }
        return (
            <div className="relative flex h-full flex-col ">
                <div className={`border-b border-elements-edmaterial p-5 text-tr-m font-medium text-main-dark ${openList === true ? " pb-[110px]" : " pb-0"} `}>
                    {active !== undefined ? (
                        <span>{active.active.name}</span>
                    ):(
                        <>
                            <span>
                                {threat.trajectory_active.active.name}
                            </span>
                            &#129042;
                        </>
                    )} 
                    {threat !== undefined ? (
                        <span>{threat.threat.name} </span>
                    ):(
                        <>
                        </>
                    )} 
                    {materials.length > 1 ? (
                        <>
                            {openList === false ? (
                                <div onClick={() => setOpenList(true)} className="cursor-pointer p-5 my-5 flex h-[70px] w-full max-w-[1040px] shadow-[0_0_10px_0_rgba(38,67,84,0.1)] rounded-md items-center justify-between">
                                    <div className="flex gap-x-[15px] items-end mt-[5px]">
                                        <div className="flex ml-[3px] mt-[10px]">
                                            <SelectedMaterial/>
                                        </div>
                                        <div className="flex gap-x-3 items-end mb-[2px]">
                                            <p className="text-tr-m text-orange font-medium w-[40px]">{selectedMaterial?.session.percent_progress}%</p>
                                            <div className="flex-col gap-y-[10px] ml-2 mt-[1px]">
                                                <p className="text-tr-xxs text-main-dark font-normal">{selectedMaterial?.content_type.custom_name}</p>
                                                <p className="text-tr-s text-main-dark font-medium">{selectedMaterial?.name}</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <ListOpen/>
                                </div>
                            ):(
                                <div onMouseLeave={() => setOpenList(false)} className="absolute cursor-pointer top-[60px] flex flex-col w-full max-w-[1040px] shadow-[0_0_10px_0_rgba(38,67,84,0.1)] rounded-md p-5 gap-y-[10px] bg-white h-full max-h-[500px] overflow-y-auto">
                                    {materials.map((m) => (
                                        <div className="flex gap-x-3">
                                            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                                                <div className="relative z-10 size-7 flex justify-center items-center mt-[7px]">
                                                    {materialId === m.id ? (
                                                        <SelectedMaterial/>
                                                    ):(
                                                        <FinishedMaterial className={` ${m.session.percent_progress < 100 ? " fill-[#BDD7E7]" : " fill-[#566DA3]"}`}/>        
                                                    )}
                                                    
                                                </div>
                                            </div>
                                            <div className="grow pt-0.5 pb-8">
                                                <div 
                                                    className="flex cursor-pointer items-end" 
                                                    onClick={() => (setMaterialId(m.id), setOpenList(false), setSelectedMaterial(m))}
                                                >
                                                    <p className={`text-tr-m  font-medium w-[40px] ${materialId === m.id ? " text-orange":" text-main-dark"}`}>{m.session.percent_progress}%</p>
                                                    <div className="flex-col gap-y-[10px] ml-5">
                                                        <p className="text-tr-xxs text-main-dark font-normal">{m.content_type.custom_name}</p>
                                                        <p className="text-tr-s text-main-dark font-medium">{m.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="pl-[7px]">
                                        Финиш
                                    </div>
                                </div>
                            )}
                        </>
                    ):(
                        <div className="flex gap-x-3 items-end my-[40px]">
                            <p className="text-tr-m text-orange font-medium w-[40px]">{selectedMaterial?.session.percent_progress}%</p>
                            <div className="flex-col gap-y-[10px] ml-2 mt-[1px]">
                                <p className="text-tr-xxs text-main-dark font-normal">{selectedMaterial?.content_type.custom_name}</p>
                                <p className="text-tr-s text-main-dark font-medium">{selectedMaterial?.name}</p>
                            </div>
                        </div>
                    )}
                    
                </div>
                <div className="h-full overflow-y-auto p-5">
                    {selectedMaterial != null ? (
                        <ContentAdapter
                            session={selectedMaterial.id}
                            material={selectedMaterial}
                            mutate={() => {}}
                            openPopupTest={false}
                            setOpenPopupTest={setOpenPopupTest}
                        />
                    ) : (
                        <div>Материал еще не выбран</div>
                    )}
                </div>
                {nextElements.length !== 0 && (
                    <div 
                        className="flex w-full justify-end pr-[20px] pb-[20px] cursor-pointer"
                        onClick={()=>setMaterialId(nextElements[0].id)}
                    >
                        <div 
                            className="flex h-[40px] w-[235px] rounded-full shadow-[0_0_10px_0_rgba(38,67,84,0.1)] items-center justify-center
                            text-tr-s font-medium text-main-dark"
                        >
                            Следующий материал →
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <section
            onClick={closePopup}
            className="fixed inset-0 z-50 bg-main-blue/30 py-[35px]"
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className="relative mx-auto flex  w-full max-w-[1805px] h-full max-h-[900px] min-h-80 flex-col rounded-xl bg-monochrome-white shadow-lg"
            >
                <button
                    className="absolute right-4 top-4 cursor-pointer z-50"
                    onClick={closePopup}
                >
                    <Cross className="fill-main-dark stroke-main-dark hover:opacity-70" />
                </button>
                {component()}
            </div>
            {!openPopupTest ? (
                ""
            ) : (
                <PopupTest
                    session={selectedMaterial.id}
                    quiz={selectedMaterial.quiz}
                    setOpenPopupTest={setOpenPopupTest}
                />
            )}
        </section>
    );
}
