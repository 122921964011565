import { Calendar } from "components/Calendar/Calendar";
import { Events } from "components/Calendar/Events";
import { Loader } from "components/Loader/Loader";
import { useSessionPopup } from "components/MaterialSessionPopup/model";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGoalsList, { getGoalsList } from "shared/api/useGoalsList";
import { useIncomplete } from "shared/api/useIncomplete";
import useLeftSkillsGoal from "shared/api/useLeftSkillsGoal";
import useMaterialsSkill from "shared/api/useMaterialsSkill";
import useSkillsGoal from "shared/api/useSkillsGoal";
import useUser from "shared/api/useUser";
import useUserWeekProgress from "shared/api/useUserWeekProgress";
import { ActiveThreatType, TrajectoryActiveType } from "shared/types";
import ProgressCircle from "shared/ui/ProgressCircle";
import { ReactComponent as AchieveLoad } from "../../shared/ui/icon/achieveLoad.svg";
import { ReactComponent as EmptyAchievements } from "../../shared/ui/icon/EmptyAchievements.svg";
import { ReactComponent as ListClose } from "../../shared/ui/icon/ListClose.svg";
import { ReactComponent as ListOpen } from "../../shared/ui/icon/ListOpen.svg";
import "./index.css";
import { useQuery } from "@tanstack/react-query";

function ActiveInfoSkeleton() {
    return (
        <div className="flex h-full max-h-[665px] w-full max-w-[335px] flex-col items-center rounded-xl px-[30px] py-[25px] shadow-[0px_7px_22px_0px_#2643541A]">
            <p className="text-tr-m font-medium text-main-dark opacity-40">
                Ваш прогресс по целям
            </p>
            <div className="mt-[20px] flex w-full items-center justify-between">
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListOpen className="rotate-90 opacity-30" />
                </div>
                <div className="flex h-[117px] w-[117px] items-center justify-center rounded-full border-[10px] border-[#D6D6D6] text-[#3F4A50]"></div>
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListClose className="rotate-90 opacity-30" />
                </div>
            </div>

            <div className="mt-[10px] flex h-[8px] w-full max-w-[135px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>

            <div className="mt-[30px] flex w-full items-center justify-between ">
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListOpen className="rotate-90 opacity-30" />
                </div>
                <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full border-[8px] border-[#D6D6D6] text-[#3F4A50]"></div>
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListClose className="rotate-90 opacity-30" />
                </div>
            </div>

            <div className="mt-[10px] flex h-[8px] w-full max-w-[135px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>

            <div className="mb-[130px] mt-[30px] flex w-full flex-col items-center gap-y-[10px]">
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
            </div>
        </div>
    );
}

function ActiveInfoEmptySkeleton() {
    return (
        <div className="flex h-full max-h-[665px] w-full max-w-[335px] flex-col items-center rounded-xl px-[30px] py-[25px] shadow-[0px_7px_22px_0px_#2643541A]">
            <p className="text-tr-m font-medium text-main-dark">
                Ваш прогресс по целям
            </p>
            <div className="mt-[20px] flex w-full items-center justify-center">
                <ProgressCircle
                    className="h-[115px] w-[115px]"
                    colorLine="#ADADAD"
                    colorText="white"
                    ringType="big"
                    progress={
                        0.35
                    }
                />    
            </div>
            <p className="mt-[10px] text-tr-s font-medium text-main-dark underline">
                Скоро тут будет цель
            </p>
            <div className="mt-[30px] flex w-full items-center justify-center ">
                <ProgressCircle
                    className="h-[80px] w-[80px]"
                    colorLine="#ADADAD"
                    colorText="white"
                    ringType="big"
                    progress={
                        0.35
                    }
                />
            </div>
            <p className="mt-[10px] text-tr-s font-medium text-main-dark underline">
                Скоро тут будет навыки
            </p>

            <div className="flex flex-col h-[280px] w-[295px] items-center justify-center gap-y-[20px]">
                <svg width="43" height="38" viewBox="0 0 43 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.76562 8.38492L17.0859 2.17639C19.9147 0.857869 23.0849 0.857871 25.9138 2.17639L39.2342 8.38501C42.1317 9.73551 42.1317 14.4104 39.2342 15.7609L25.914 21.9694C23.0851 23.288 19.9149 23.288 17.0861 21.9694L7.56475 17.5316" stroke="#ADADAD" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M2.58789 24.9382V19.273C2.58789 16.7412 2.58789 15.4753 3.24547 14.4838C3.90304 13.4924 5.07339 12.9937 7.41409 11.9964L9.55548 11.084" stroke="#ADADAD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21.5016 36.8118C17.9262 36.8118 13.2474 34.5927 10.3242 32.9833C8.56875 32.0167 7.56641 30.1489 7.56641 28.1538V18.0098M35.4368 18.0098V28.1538C35.4368 30.1489 34.4344 32.0167 32.6789 32.9833C31.623 33.5646 30.3381 34.2255 28.9588 34.8327" stroke="#ADADAD" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
                <p className="flex w-full max-w-[140px] text-tr-xs font-medium text-[#ADADAD] text-center">
                    Скоро здесь будут учебные материалы
                </p>
            </div>
        </div>
    );
}

function MainGoalSkeleton() {
    return (
        <div className="flex h-full w-full max-w-[1415px] gap-x-[25px]">
            <div className="flex h-full w-full max-w-[1415px] gap-x-[25px] ">
                <div
                    className={`z-50 flex h-full max-h-[665px] w-full max-w-[575px] flex-col rounded-xl bg-[#3F4A50] px-[20px] py-[25px]`}
                >
                    <div className="flex w-full items-start justify-between">
                        <div className="flex w-[375px] flex-col gap-y-[15px] text-tr-s font-medium text-white">
                            <p className="text-tr-s font-medium text-[#D6D6D6]">
                                Вы уже начали достигать цель
                            </p>
                            <div className="flex h-[20px] w-full max-w-[340px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                        </div>
                        <div className="flex h-[144px] w-[144px] items-center justify-center rounded-full border-[10px] border-[#D6D6D6] text-[#3F4A50]">
                            f
                        </div>
                    </div>
                    <div className="mt-[25px] flex h-full flex-col gap-y-[20px]">
                        <div className="flex w-full max-w-[420px] flex-col gap-y-[40px]">
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                        </div>
                    </div>
                    <div
                        className={` mt-[20px] flex w-full items-center justify-between `}
                    >
                        <div className="flex h-[16px] w-full max-w-[195px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                            f
                        </div>
                        <div className="flex h-[55px] w-[55px] rotate-45 cursor-pointer items-center justify-center rounded-full bg-white text-[30px] text-[#264354B2] opacity-20">
                            &#8594;
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex h-full max-h-[665px] w-full max-w-[455px] flex-col gap-y-[20px] rounded-xl">
                <div className="flex h-full max-h-[190px] w-full max-w-[455px] flex-col rounded-xl p-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                    <div className="flex w-full justify-between opacity-40">
                        <p className="text-tr-s font-medium text-[#264354]">
                            Средняя эффективность
                        </p>
                        <div className="flex items-center gap-x-[10px]">
                            <p className="text-tr-s font-normal text-[#264354]">
                                Неделя
                            </p>
                            <ListOpen className="mb-[2px]" />
                        </div>
                    </div>
                    <div className="mt-[25px] flex items-end gap-x-[28px]">
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[66px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[82px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[58px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[64px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[20px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[78px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[82px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                    </div>
                </div>

                <div className="flex h-full max-h-[455px] w-full max-w-[455px] flex-col rounded-xl p-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                    <p className="text-tr-s font-semibold text-main-dark opacity-40">
                        Ваши достижения
                    </p>
                    <div className="mt-[20px] grid grid-cols-2 gap-x-2 gap-y-2 overflow-y-auto">
                        <div className="group flex h-[145px] w-full cursor-pointer flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                        <div className="group flex h-[145px] w-full cursor-pointer flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                        <div className="group flex h-[145px] w-full cursor-pointer  flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                        <div className="group flex h-[145px] w-full cursor-pointer flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                    </div>
                    <div className="mt-[20px] flex w-full items-center justify-between">
                        <div className="flex h-[12px] w-full max-w-[195px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        <div className="flex h-[55px] w-[55px] rotate-45 cursor-pointer items-center justify-center rounded-full bg-[#264354B2] text-[30px] text-white opacity-20">
                            &#8594;
                        </div>
                    </div>
                </div>
            </div>

            <ActiveInfoSkeleton />
        </div>
    );
}

function LeftSkill({ skill }) {
    const skillStat = (
        skill?.completed_content_count / skill?.content_count || 0
    ).toPrecision(1);
    console.log({skillStat});
    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLinkThreat = (threatId: number) => {
        if (typeof threatId !== "number") {
            throw new Error("invalid threatId");
        }
        openActiveMaterial(null, threatId, null);
    };

    return (
        <div className="flex w-full cursor-pointer justify-between" onClick={() => handleClickLinkThreat(skill.id)}>
            <p className="text-tr-m font-medium text-[#FFFFFF]">
                {skill?.threat.name}
            </p>
            <div className="flex gap-x-[5px]">
                <p className="text-[#369F48]">{Number(skillStat) * 100} %</p>
                <p
                    className="hidden -rotate-90 text-white group-hover:block"
                >
                    &#9660;
                </p>
            </div>
        </div>
    );
}

function DayProgress({ day, index }) {
    const daysOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

    const dayStat = (
        day.completed_materials / day.opened_materials || 0
    ).toPrecision(1);

    const dayStatString = String(Number(dayStat) * 100)

    return (
        <div
            key={index}
            className="group flex cursor-pointer flex-col items-center justify-end gap-y-[3px]"
        >
            <div className="flex h-[18px] w-[35px] items-center justify-center rounded bg-[#264354] opacity-0 group-hover:opacity-100 ">
                <p className="text-tr-xs font-medium text-white">
                    {Number(dayStat) * 100}%
                </p>
            </div>
            <div
                className={`h-[${dayStatString}px] w-[35px] rounded bg-[#8591AD] group-hover:bg-[#264354]`}
            ></div>
            <p className="text-tr-s font-medium text-[#264354]">
                {daysOfWeek[index]}
            </p>
        </div>
    );
}

function WeekProgress({ weekData }) {
    // Define an array of day names for easier mapping

    const weekData2 = [
        {
            day: "2024-09-02",
            opened_materials: 2,
            completed_materials: 1,
        },
        {
            day: "2024-09-03",
            opened_materials: 3,
            completed_materials: 1,
        },
        {
            day: "2024-09-04",
            opened_materials: 4,
            completed_materials: 1,
        },
        {
            day: "2024-09-05",
            opened_materials: 1,
            completed_materials: 1,
        },
        {
            day: "2024-09-06",
            opened_materials: 5,
            completed_materials: 1,
        },
        {
            day: "2024-09-07",
            opened_materials: 8,
            completed_materials: 2,
        },
        {
            day: "2024-09-08",
            opened_materials: 6,
            completed_materials: 4,
        },
    ];

    return (
        <div className="mt-[10px] flex h-[140px] items-end justify-between">
            {weekData.map((day, index) => (
                <DayProgress day={day} index={index} />
            ))}
        </div>
    );
}

function MainPage() {
    const { user, isLoading: isUserLoading, isError: isUserError } = useUser();
    // const { userData, isLoading, isError } = useUserInfo();
    const activeAchievementsList = useIncomplete();

    const { goalsList, isLoadingGoal, isErrorGoal } = useGoalsList();

    

    const activeGoalList = goalsList?.results.filter(
        (item) => item.content_count > 0
    );

    const [loadPage, setLoadPage] = useState(true);
    const [loadActiveInfo, setLoadActiveInfo] = useState(false);

    const navigate = useNavigate();
    const [openList, setOpenList] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [active, setActive] = useState<TrajectoryActiveType>();
    const [threat, setThreat] = useState<ActiveThreatType>();
    const [leftActive, setLeftActive] = useState<TrajectoryActiveType>();
    const [openCard, setOpenCard] = useState(false);
    const daysOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

    function getData(activeGoalList: TrajectoryActiveType[]){
        if(activeGoalList?.length !== 0){
            const LeftActiveGoalList = activeGoalList?.reduce((acc, curr) =>
                acc.completed_threats_count[0] / acc.threats_count[1] >
                curr.completed_threats_count[0] / curr.threats_count[1]
                    ? curr
                    : acc
            );
            return LeftActiveGoalList;
        }
    };

    const LeftActiveGoalList = getData(activeGoalList);
    
    const activeGoalList2 = activeGoalList?.filter(
        (m) => m.id !== LeftActiveGoalList.id
    );

    const { skillList, isLoadingSkill, isErrorSkill } = useSkillsGoal(
        active?.id
    );
    
    const activeSkillList = skillList?.results.filter(
        (item) => item.content_count > 0
    );

    const { materialsList, isLoadingMaterial } = useMaterialsSkill(threat?.id);
    const firstMaterial = materialsList?.results[0];

    const { leftSkillList } = useLeftSkillsGoal(leftActive?.id);
    const leftactiveSkillList = leftSkillList?.results.filter(
        (item) => item.content_count > 0
    );

    const [activeIndex, setActiveIndex] = useState(0);
    const [threatIndex, setThreatIndex] = useState(0);

    const { weekProgress } = useUserWeekProgress();

    const sum = weekProgress?.reduce((partialSum, week) => partialSum + week.opened_materials, 0) || 0;
    
    const handleLinkGoal = (id) => {
        if (leftActive !== undefined) {
            console.log(active);
            navigate(`timeline/${id}`, { state: { leftActive } });
        }
    };

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLink = () => {
        if (typeof active.id !== "number") {
            throw new Error("invalid activeId");
        }
        openActiveMaterial(active.id, null, firstMaterial.id);
        // navigate(`/session/${item.id}`);
    };

    const handleClickLinkActive = (materialId: number) => {
        if (typeof active.id !== "number") {
            throw new Error("invalid activeId");
        }
        openActiveMaterial(active.id, null, materialId);
    };

    const handleClickLinkThreat = (threatId: number, materialId: number) => {
        if (typeof threatId !== "number") {
            throw new Error("invalid threatId");
        }
        openActiveMaterial(null, threatId, materialId);
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });

        if (activeGoalList2 !== undefined) {
            if (activeIndex >= 0 && activeIndex <= activeGoalList2.length - 1) {
                setActive(activeGoalList2[activeIndex]);
            } else {
                if (activeIndex <= 0) {
                    setActiveIndex(0);
                } else {
                    setActiveIndex(activeGoalList2.length - 1);
                }
                if (activeGoalList2.length == 0) {
                    setActive(activeGoalList[activeIndex]);
                }
            }
        }
        if (activeSkillList !== undefined) {
            if (threatIndex >= 0 && threatIndex <= activeSkillList.length - 1) {
                setThreat(activeSkillList[threatIndex]);
            } else {
                if (threatIndex <= 0) {
                    setThreatIndex(0);
                } else {
                    setThreatIndex(activeSkillList.length - 1);
                }
            }
        }
        if (LeftActiveGoalList !== undefined) {
            setLeftActive(LeftActiveGoalList);
        }
        // console.log(activeGoalList2);

        // console.log(activeSkillList);
        if (
            activeGoalList !== undefined &&
            activeSkillList !== undefined &&
            materialsList !== undefined
        ) {
            setLoadPage(false);
        }
        if (
            isLoadingGoal === true ||
            isLoadingSkill === true ||
            isLoadingMaterial === true ||
            activeGoalList.length === 1
        ) {
            setLoadActiveInfo(true);
        } else {
            setLoadActiveInfo(false);
        }
    }, [
        width,
        activeGoalList,
        activeGoalList2,
        activeIndex,
        active,
        threatIndex,
        threat,
        activeSkillList,
        LeftActiveGoalList,
        materialsList,
        isLoadingGoal,
        isLoadingSkill,
    ]);

    // TODO добавить крутилку
    if (isUserLoading || isUserError) return <Loader text="Загрузка..." />;

    return (
        <section className="relative flex h-full w-full flex-col items-center overflow-y-scroll px-[5px] pb-[26px] max-[834px]:pb-[76px]">
            <div className="mt-[30px] flex w-full max-w-[1415px]">
                <div className="mr-[10px] flex w-full max-w-[1415px] flex-col gap-[65px] max-[834px]:gap-[60px]">
                    <div className="flex flex-col gap-[40px] mb-[20px]">
                        <h2 className="text-[20px] font-bold leading-[24px] text-[#264354] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
                            Здравствуй, {user?.first_name}!
                        </h2>
                        <p className="text-tr-m font-semibold text-[#264354]">
                            Продолжайте достигать цели:
                        </p>
                    </div>
                </div>
            </div>
            {loadPage === false ? (
                <div className="flex w-full max-w-[1415px]">
                    <div
                        className="
                        gap-x-[25px] 
                        gap-y-[35px] 
                        max-[800px]:flex
                        max-[800px]:w-full
                        max-[800px]:flex-col
                        max-[800px]:items-center
                        min-[800px]:grid 
                        min-[800px]:grid-cols-[minmax(335px,455px),minmax(420px,455px)] 
                        min-[800px]:grid-rows-3 
                        min-[1070px]:grid-cols-[minmax(420px,575px),minmax(330px,455px),minmax(250px,335px)] 
                        min-[1070px]:grid-rows-2 "
                    >
                        <div className="relative flex w-full">
                            <div
                                className={` z-40 flex w-full flex-col  ${
                                    openList === true
                                        ? "absolute left-0 right-0 min-h-[665px] w-full"
                                        : " h-full max-h-[665px]"
                                }  rounded-xl bg-[#3F4A50] px-[20px] py-[25px] `}
                            >
                                <div className="mt-[20px] flex w-full items-start justify-between">
                                    <div className="flex w-[375px] flex-col gap-y-[15px] text-tr-s font-medium text-white">
                                        <p className="text-tr-s font-medium text-white">
                                            Вы уже начали достигать цель
                                        </p>
                                        <p className="break-words text-tr-l font-bold text-white">
                                            {leftActive?.active.name}
                                        </p>
                                    </div>
                                    <ProgressCircle
                                        className="h-[144px] w-[144px]"
                                        colorLine="#FCA86F"
                                        colorText="white"
                                        ringType="big"
                                        progress={
                                            leftActive?.completed_threats_count /
                                            leftActive?.threats_count
                                        }
                                    />
                                </div>
                                <div className="mt-[25px] flex h-full flex-col gap-y-[20px]">
                                    {leftactiveSkillList !== undefined && (
                                        <>
                                            {leftactiveSkillList?.map(
                                                (skill) => {
                                                    return (
                                                        <div className="group flex w-full max-w-[420px] flex-col gap-y-[10px] opacity-50 hover:opacity-100">
                                                            <LeftSkill
                                                                skill={skill}
                                                            />
                                                            <ProgressBar
                                                                background="#369F48"
                                                                color="#369F48"
                                                                height="3px"
                                                                fontSize=""
                                                                lineHeight=""
                                                                top=""
                                                                right=""
                                                                percent={
                                                                    skill?.completed_content_count /
                                                                    skill?.content_count
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </>
                                    )}
                                </div>
                                <div
                                    className={` mt-[20px] flex w-full items-center justify-between `}
                                >
                                    <>
                                        {leftactiveSkillList?.length > 7 ? (
                                            <>
                                                {openList === false ? (
                                                    <div
                                                        className="flex cursor-pointer gap-x-[5px] text-tr-s font-medium text-white"
                                                        onClick={() =>
                                                            setOpenList(true)
                                                        }
                                                    >
                                                        <p>
                                                            и еще{" "}
                                                            {leftactiveSkillList?.length -
                                                                7}{" "}
                                                            навыков
                                                        </p>
                                                        &#8595;
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="flex cursor-pointer gap-x-[5px] text-tr-s font-medium text-white"
                                                        onClick={() =>
                                                            setOpenList(false)
                                                        }
                                                    >
                                                        <p>Свернуть</p>
                                                        &#8593;
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div></div>
                                        )}
                                    </>
                                    <>
                                        {leftActive !== undefined && (
                                            <button 
                                                className="block hover:-rotate-90" 
                                                onClick={() =>
                                                    handleLinkGoal(
                                                        leftActive.id
                                                    )
                                                }
                                            >
                                                <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="27.5" cy="27.5" r="27.5" fill="white"/>
                                                    <path d="M39.1354 34.2175L33.3619 24.2175L27.5884 34.2175L39.1354 34.2175ZM15.3652 21.6485L30.8412 30.5835L31.8412 28.8515L16.3652 19.9164L15.3652 21.6485Z" fill="#3F4A50"/>
                                                </svg>
                                            </button>
                                        )}
                                    </>
                                </div>
                            </div>
                        </div>

                        <div className=" flex h-full max-h-[665px]  w-full flex-col gap-y-[20px] rounded-xl ">
                            <div className="flex h-full max-h-[190px] w-full flex-col rounded-xl px-[20px] pb-[10px] pt-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                                <div className="flex w-full justify-between">
                                    <p className="text-tr-s font-medium text-[#264354]">
                                        Средняя эффективность
                                    </p>
                                    {/* <div className="flex cursor-pointer items-center gap-x-[10px]">
                                        <p className="text-tr-s font-normal text-[#264354]">
                                            Неделя
                                        </p>
                                        <ListOpen className="mb-[2px]" />
                                    </div> */}
                                </div>

                                {sum > 0 ? (
                                    <WeekProgress weekData={weekProgress} />
                                ):(
                                    
                                    <div className="flex flex-col h-full w-full items-center justify-between">
                                        <p className="text-tr-s font-medium text-[#73C3F3] mt-[55px]">Данных об эффективности нет</p>
                                        <div className="flex w-full items-end justify-between pl-[7px] pr-[8px]">
                                            {daysOfWeek.map((day, index) => (
                                                <div
                                                    key={index}
                                                    className="flex cursor-pointer items-center"
                                                >
                                                    <p className="text-tr-s font-medium text-[#264354]">
                                                        {daysOfWeek[index]}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                
                            </div>

                            <div className="flex h-full max-h-[455px] w-full flex-col rounded-xl py-[20px] px-[25px] shadow-[0px_7px_22px_0px_#2643541A]">
                                <p className="text-tr-s font-semibold text-main-dark">
                                    Ваши достижения
                                </p>
                                <div className="w-fullrounded-xl flex h-full flex-col items-center justify-center gap-y-[15px]">
                                    <EmptyAchievements />
                                    <p className="text-tr-s font-semibold text-main-dark">
                                        Пока что тут пусто
                                    </p>
                                </div>
                                {/* <svg width="56" height="55" viewBox="0 0 56 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="28" cy="27.5" r="27.5" fill="#264354" fill-opacity="0.7"/>
                                    <path d="M38.8819 33.7822L33.1084 23.7822L27.3349 33.7822L38.8819 33.7822ZM15.8652 21.6483L30.5877 30.1483L31.5877 28.4162L16.8652 19.9162L15.8652 21.6483Z" fill="white"/>
                                </svg> */}
                            </div>
                        </div>

                        {loadActiveInfo === true ? (
                            <div className="flex h-full max-h-[665px]  w-full flex-col items-center rounded-xl px-[20px] pt-[20px] pb-[10px] shadow-[0px_7px_22px_0px_#2643541A]">
                                <p className="text-tr-m font-medium text-main-dark">
                                    Ваш прогресс по целям
                                </p>
                                {goalsList.results.length !== 0 ? (
                                    <>
                                        <div className="mt-[20px] flex w-full items-center justify-between">
                                            <div
                                                onClick={() =>
                                                    setActiveIndex(
                                                        activeIndex - 1
                                                    )
                                                }
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListOpen className="rotate-90 " />
                                            </div>
                                            <ProgressCircle
                                                className="h-[115px] w-[115px]"
                                                colorLine="#FCA86F"
                                                colorText="black"
                                                ringType="middle"
                                                progress={
                                                    active?.completed_threats_count /
                                                    active?.threats_count
                                                }
                                            />
                                            <div
                                                onClick={() =>
                                                    setActiveIndex(
                                                        activeIndex + 1
                                                    )
                                                }
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListClose className="rotate-90 " />
                                            </div>
                                        </div>

                                        <p className="mt-[10px] text-tr-s font-medium text-main-dark">
                                            {active?.active.name}
                                        </p>

                                        <div className="mt-[30px] flex w-full items-center justify-between ">
                                            <div
                                                onClick={() =>
                                                    setThreatIndex(
                                                        threatIndex - 1
                                                    )
                                                }
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListOpen className="rotate-90 " />
                                            </div>
                                            <ProgressCircle
                                                className="h-[80px] w-[80px]"
                                                colorLine="#369F48"
                                                colorText="black"
                                                ringType="short"
                                                progress={
                                                    threat?.completed_content_count /
                                                    threat?.content_count
                                                }
                                            />
                                            <div
                                                onClick={() =>
                                                    setThreatIndex(
                                                        threatIndex + 1
                                                    )
                                                }
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListClose className="rotate-90 " />
                                            </div>
                                        </div>

                                        <p className="mt-[10px] text-tr-s font-medium text-main-dark">
                                            {threat?.threat.name}
                                        </p>

                                        <div className="flex h-[280px] w-full flex-col gap-y-[10px] overflow-y-auto mt-[20px]">
                                            {materialsList !== undefined || materialsList.results.length !== 0 ?(
                                                <>
                                                    {materialsList?.results.map(
                                                        (material) => {
                                                            return (
                                                                <div
                                                                    onClick={() =>
                                                                        handleClickLinkThreat(
                                                                            threat.id,
                                                                            material.id
                                                                        )
                                                                    }
                                                                    className="group flex h-full max-h-[60px] w-full max-w-[295px] cursor-pointer justify-between p-[10px] hover:bg-blue hover:bg-opacity-20"
                                                                >
                                                                    <div className="flex w-full flex-col gap-y-[5px]">
                                                                        <p className="text-tr-xs font-normal text-[#566DA3]">
                                                                            {
                                                                                material
                                                                                    .content_type
                                                                                    .verbose_name
                                                                            }
                                                                        </p>
                                                                        <p className="text-tr-s font-medium text-[#26435499] underline">
                                                                            {
                                                                                material.name
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <div className="opacity-0 group-hover:opacity-100">
                                                                        &#8594;
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            ):(
                                                <div className="flex flex-col h-full w-full items-center justify-center gap-y-[10px]">
                                                    <svg width="43" height="38" viewBox="0 0 43 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.76562 8.38492L17.0859 2.17639C19.9147 0.857869 23.0849 0.857871 25.9138 2.17639L39.2342 8.38501C42.1317 9.73551 42.1317 14.4104 39.2342 15.7609L25.914 21.9694C23.0851 23.288 19.9149 23.288 17.0861 21.9694L7.56475 17.5316" stroke="#566DA3" stroke-width="1.5" stroke-linecap="round"/>
                                                        <path d="M2.58789 24.9382V19.273C2.58789 16.7412 2.58789 15.4753 3.24547 14.4838C3.90304 13.4924 5.07339 12.9937 7.41409 11.9964L9.55548 11.084" stroke="#566DA3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M21.5016 36.8118C17.9262 36.8118 13.2474 34.5927 10.3242 32.9833C8.56875 32.0167 7.56641 30.1489 7.56641 28.1538V18.0098M35.4368 18.0098V28.1538C35.4368 30.1489 34.4344 32.0167 32.6789 32.9833C31.623 33.5646 30.3381 34.2255 28.9588 34.8327" stroke="#566DA3" stroke-width="1.5" stroke-linecap="round"/>
                                                    </svg>
                                                    <p className="flex w-full max-w-[140px] text-tr-xs font-medium text-[#566DA3] text-center">
                                                        Скоро здесь будут учебные материалы
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <div
                                            onClick={handleClickLink}
                                            className="flex h-full max-h-[35px] w-full max-w-[295px] cursor-pointer items-center justify-center rounded-full bg-[#566DA3] text-white"
                                        >
                                            Изучить сразу
                                        </div>
                                    </>
                                ) : (
                                    <>Пока что нет добавленных целей</>
                                )}
                            </div>
                        ) : (
                            <ActiveInfoEmptySkeleton />
                        )}
                        <Calendar />

                        <Events />
                    </div>
                </div>
            ) : (
                <MainGoalSkeleton />
            )}
        </section>
    );
}

export default MainPage;
